import React from 'react'

export default () => {
  return (
    <svg
      width='31'
      height='15'
      viewBox='0 0 31 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.7072 8.20711C31.0977 7.81659 31.0977 7.18342 30.7072 6.7929L24.3432 0.428938C23.9527 0.0384133 23.3195 0.0384132 22.929 0.428937C22.5385 0.819462 22.5385 1.45263 22.929 1.84315L28.5858 7.50001L22.929 13.1569C22.5385 13.5474 22.5385 14.1805 22.929 14.5711C23.3195 14.9616 23.9527 14.9616 24.3432 14.5711L30.7072 8.20711ZM0.206055 8.5L30 8.50001L30 6.50001L0.206055 6.5L0.206055 8.5Z'
        fill='#0400B9'
      />
    </svg>
  )
}
