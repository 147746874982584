import React from 'react'
import { Footer } from './../footer'

const Layout = ({ children }) => {
  return (
    <main>
      {children}
      <Footer />
    </main>
  )
}

export default Layout
