import React from 'react'
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap
} from 'react-google-maps'

import styles from './map-styles.js'

export default withScriptjs(
  withGoogleMap(() => {
    return (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={{ lat: 50.028247, lng: 20.0471196 }}
        options={{
          styles
        }}
      >
        <Marker
          position={{ lat: 50.028765, lng: 20.048363 }}
          onClick={() => {
            window
              .open(
                'https://www.google.com/maps/place/Stalproject/@50.0286674,20.0461647,17z/data=!3m1!4b1!4m5!3m4!1s0x4716452f0ca7250f:0x7b81da9d1567768f!8m2!3d50.0286674!4d20.0483534?hl=pl',
                '_blank'
              )
              ?.focus()
          }}
        />
      </GoogleMap>
    )
  })
)
