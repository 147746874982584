import React from 'react'

import '/src/assets/scss/style.scss'

import { Map } from '/src/components'

export const Contact = ({ map = false, content = null }) => {
  return (
    <section className='contact'>
      {map && (
        <div className='map'>
          <Map
            googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBRj_OczQMjzGQiQ8GWytDtGwujOjgER5E&v=3.exp&libraries=places'
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      )}
      <div className='contact__content'>
        <div className='wrapper'>
          <h2>Kontakt</h2>
          <div className='contact__columns'>
            <div className='contact__column'>
              <h5>Stalproject Sp. z o.o.</h5>
              <p className='contact__details'>
                <a
                  target='_blank'
                  href='https://www.google.com/maps/place/Stalproject/@50.0286674,20.0461647,17z/data=!3m1!4b1!4m5!3m4!1s0x4716452f0ca7250f:0x7b81da9d1567768f!8m2!3d50.0286674!4d20.0483534?hl=pl'
                >
                  Ul. Domagały 1,
                  <br />
                  30-741 Kraków
                </a>
              </p>
              <p className='contact__details'>
                Godziny pracy:
                <br />
                7:00 - 15:00
              </p>
              <p className='contact__details'>
                <a href='tel:+48 516 032 362'>tel: +48 516 032 362</a>
                <br />
                <a href='mailto:zarzad@stalproject.eu'>zarzad@stalproject.eu</a>
              </p>
            </div>
            <div className='contact__column'>
              <h5>&nbsp;</h5>
              <p>Sprzedaż</p>
              <p className='contact__details'>
                <a href='tel:+48 729 847 611'>tel: +48 729 847 611</a>
                <br />
                <a href='mailto:rafal.sobczak@stalproject.eu'>
                  rafal.sobczak@stalproject.eu
                </a>
              </p>
            </div>
            <div className='contact__column'>
              <h5>&nbsp;</h5>
              <p>Produkcja</p>
              <p className='contact__details'>
                <a href='tel:+48 500 010 494'>tel: +48 500 010 494</a>
                <br />
                <a href='mailto:bruno.kowalczuk@stalproject.eu'>
                  bruno.kowalczuk@stalproject.eu
                </a>
              </p>
            </div>
          </div>
          {/* <p className='small'>
            <br />
            Nasza hala produkcyjna usytuowana jest na terenie krakowskiej
            dzielnicy XII Bieżanów-Prokocim w niedalekim sąsiedztwie autostrady
            A4 i obwodnicy Krakowa S7.
          </p> */}
        </div>
      </div>
    </section>
  )
}
