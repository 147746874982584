import React from 'react'
import 'normalize.css/normalize.css'

import '/src/assets/scss/style.scss'

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='wrapper'>&copy; 2022 Stalproject Sp. z o.o.</div>
    </footer>
  )
}
