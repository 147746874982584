import React, { useState } from 'react'
import 'normalize.css/normalize.css'

import '/src/assets/scss/style.scss'

import { Logo } from '/src/components'

const nav = [
  { href: '/#offer', link: 'Produkty' },
  // { href: '/#projects', link: 'Realizacje' },
  // { href: '/#', link: 'Rekomendacje' },
  { href: '/#about', link: 'O nas' },
  { href: '/#contact', link: 'Kontakt' }
]

const Header = () => {
  const [navToggle, setNavToggle] = useState(false)
  const onNavToggleClick = () => {
    setNavToggle(prev => !prev)
  }

  const onNavLinkClick = () => {
    setNavToggle(false)
  }

  return (
    <header className='header'>
      <div className='wrapper'>
        <Logo />
        <input
          type='checkbox'
          className='mobile-nav-toggle__state'
          id='mobile-nav__toggle'
          checked={navToggle}
        />
        <label
          className='mobile-nav-toggle__wrapper'
          htmlFor='mobile-nav__toggle'
          onClick={onNavToggleClick}
        >
          <span className='toggle toggle--top'></span>
          <span className='toggle toggle--middle'></span>
          <span className='toggle toggle--bottom'></span>
        </label>
        <nav className='nav main-nav'>
          <ul className='nav__menu'>
            {nav.map(({ href, link }) => (
              <li className='main-nav__item'>
                <a
                  href={href}
                  className='main-nav__link'
                  onClick={onNavLinkClick}
                >
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
