import React from 'react'

import { Arrow } from '/src/components'

export default ({ onClick, children, arrow = true }) => {
  return (
    <button onClick={onClick} type='button' className='more-button'>
      {children}
      {arrow && <Arrow />}
    </button>
  )
}
